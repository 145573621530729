@media screen and (min-width: 970px) {
  .search-tools.refine-search {
    margin-bottom: 5px;
    display: block;
  }

  .search-tools.refine-search .tool-set {
    width: auto;
    left: 0;
    position: relative;
    display: block;
    overflow-y: visible;
    padding: 0;
    z-index: 999;
  }

  .search-tools.refine-search .tool-set .buttons.hidden {
    background: 0;
  }

  .search-tools.refine-search .tool-set .inner {
    padding-bottom: 0;
    overflow-y: inherit;
  }

  .search-tools.refine-search.submittable .tool-set {
    padding-bottom: 52px;
  }

  .search-tools.refine-search .tool-set li .custom-option {
    padding: 4px 0;
  }

  .search-tools.refine-search .tool-set .header.sort-title {
    background-color: var(--wl-content-background-color-default);
    font-size: 14px;
    padding: 4px 10px;
  }

  .search-tools.refine-search .tool-set .header.sort-title a {
    color: var(--wl-text-color-default);
  }

  .search-tools.refine-search .tool-set .collapse-content .collapsible .header.empty {
    padding: 0 20px 0 0;
  }

  .search-tools.refine-search .buttons {
    width: 300px;
    height: 52px;
    bottom: 0;
    padding: 12px 0 0;
    left: auto;
    position: absolute;
    display: block;
    transition: height .2s ease-in-out;
    opacity: 1;
  }

  .search-tools.refine-search.submittable .buttons {
    transform: none;
  }

  .search-tools.refine-search .buttons.fixed {
    position: fixed;
  }
}
