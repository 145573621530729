
.branded-header-v2 .logo-container{
  display: flex;
  position: relative;
  justify-content: center;
  height: 120px;
}

.branded-header-v2 .banner-container{
  display: flex;
  position: relative;
  justify-content: center;
}

.branded-header-v2 .banner {
  height: 150px;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.branded-header-v2 .banner-logo {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
}

.branded-header-v2 .detail-description {
  text-align: justify;
}


.branded-header-v2 .logo-container .logo-frame {
  position: absolute;
  bottom: 0px;
  width: 208px;
  padding: 16px;
}

.branded-header-v2 .logo-container .logo-frame:empty {
  height: 120px;
}

.branded-header-v2 .logo-container .logo-frame .logo {
  height: 79px;
  width: 100%;
  object-fit: contain;
}

.branded-header-v2 .information-container {
  display: block;
  margin: 15px;
  text-align: center;
}

.branded-header-v2 .information-container h1 {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-large);
}
.branded-header-v2 .title-container h1 {
  margin: 0;
}

.branded-header-v2 .information-container .party-description {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  white-space: pre-line;
}

.branded-header-v2 .title-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
}

.branded-header-v2 .title-container.no-description {
  padding-bottom: 0px;
}

.branded-header-v2 .navigation-options {
  flex-direction: row;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px 0px;
  border-top: 2px solid var(--wl-border-color-default);
  border-bottom: 2px solid var(--wl-border-color-default);
}

.branded-header-v2 .navigation-options a {
  color: inherit;
  cursor: pointer;
  padding: 15px 35px;
}

.branded-header-v2 .navigation-options a:hover {
  color: var(--wl-header-hover-text-color);
  box-shadow: inset 0 -2px 0 var(--wl-background-color-four);
  transition: 0.3s;
}

.branded-header-v2 .contact-form-modal {
  border-radius: 15px;
}

.branded-header-v2 .address-modal {
  border-radius: 15px;
}

@media screen and (min-width: 769px) {
  .branded-header-v2 .logo-container {
    justify-content: center;
    height: auto;
  }

  .branded-header-v2 .information-container {
    margin: 15px 15px;
  }

  .branded-header-v2 .logo-container .logo-frame {
    padding: 20px;
    width: 260px;
    position: relative;
  }

  .branded-header-v2 .logo-container .logo-frame .logo {
    height: 120px;
    max-height: 120px;
  }

  .branded-header-v2 .title-container {
    flex-direction: row;
  }

}

@media screen and (min-width: 970px) {
  .branded-header-v2 {
    margin-bottom: 10px;
    border-bottom: 1px solid var(--wl-border-color-default);
  }
}

.dealer-contact-area-v2 {
  padding: 16px;
  margin: 15px 0px;
  text-align: center;
}

.dealer-contact-area-v2.title {
  padding: 0; 
}

.dealer-contact-area-v2 .party-description-container {
  margin: 15px auto;
  max-width: 1000px;
}

.dealer-contact-area-v2 .form-button-array {
  flex-direction: row;
}

.dealer-address {
  margin: 25px 0px;
}

.dealer-address span::before {
  content: url(../../../../icons/Location.svg);
  padding-right: 6px;
}

@media screen and (min-width: 769px) {
  .dealer-contact-area-v2 {
    justify-content: center;
    padding: 0px 12px 0 16px; 
  }

  .dealer-contact-area-v2.title {
    justify-content: center;
    padding: 0; 
    margin: 0 0 0 8px;
  }

  .branded-header-v2 .banner-logo {
    background-size: cover;
  }

  .branded-header-v2 .banner {
    height: 400px;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}
