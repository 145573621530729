.paying-brand-hero-image {
  margin-top: -2px;
  display: block;
  height: 252px;
  background-position: center;
  background-size: cover;
  background-repeat: repeat-x;
}

.paying-brand-hero-image.mobile {
  margin-top: -10px;
  height: 130px;
}
.paying-brand-hero-button-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 27px;
  margin-top: -45px;
}

@media screen and (min-width: 1440px) {
  .paying-brand-hero-button-container {
    padding-right: 0;
    max-width: 1500px;
  }
}
.paying-brand-hero-button  {
  box-sizing: border-box;
  padding: 4px 12px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #EDEDED;
  border-radius: 50px;
  min-width: 157px;
  background-color: #FCFCFC;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .paying-brand-hero-button {
    min-width: 143px;
  }
}

.paying-brand-hero-button:after {
  display: inline-block;
  content: '';
  background: url('../../../icons/ArrowForward.svg') no-repeat 50% 50%;
  background-size: 65%;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.paying-brand-hero-image .paying-brand-logo {
  display: flex;
  width: 342px;
  padding-right: 40px;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='382' height='250' viewBox='0 0 382 250' fill='none' style='&%2310; /* border: red; */&%2310;'%3E%3Cpath d='M0 250V-2L382 -0.0909091L342.259 250H0Z' fill='white' fill-opacity='0.7'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.paying-brand-hero-image.mobile .paying-brand-logo {
  width: 174px;
  padding-right: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='194' height='130' viewBox='0 0 194 130' fill='none'%3E%3Cpath d='M0 132V-3L194 -1.97727L173.817 132H0Z' fill='white' fill-opacity='0.7'/%3E%3C/svg%3E");
}

.paying-brand-hero-image .paying-brand-logo img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  padding: 15px;
}

.paying-brand-hero-image.mobile .paying-brand-logo img {
  margin: auto;
  width: 132px;
}