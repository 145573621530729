.search-alerts {
  width: 100%;
  height: 100%;
}

.search-alerts > .search-alerts-button {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
}

.search-alerts > .search-alerts-button:disabled {
  background-color: var(--wl-button-disabled-background);
  color: var(--wl-text-color-disabled);
  cursor: not-allowed;
}

.three-column-listing .search-alerts>.search-alerts-button:hover,
.three-column-listing .search-alerts>.search-alerts-button:focus-visible {
    background-color: #05275A;
    border-color: #05275A;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.20);
}


.three-column-listing .search-alerts>.search-alerts-button:active {
    background-color: #05275A;
    border-color: #05275A;
}