.sponsored-listings {
  position: relative;
  margin: 0;
  z-index: 2;
}

.sponsored-listings ul {
  margin: 0;
  padding: 0;
}

.sponsored-listings ul li {
  display: block;
  position: relative;
  overflow: hidden;
  background: var(--wl-content-background-color-default);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.34);
  margin-bottom: var(--wl-font-size-small);
}

.sponsored-listings ul li a {
  display: block;
  overflow: hidden;
}

.sponsored-listings ul li .boat-image {
  float: left;
  width: 160px;
  height: 117px;
  position: relative;
  overflow: hidden;
}

.sponsored-listings ul li .boat-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
}

.sponsored-listings ul li .information {
  padding: 10px;
}

.sponsored-listings ul li .information .make-model,
.sponsored-listings ul li .information .seller-name,
.sponsored-listings ul li .information .price,
.sponsored-listings ul li .information .location {
  font-size: var(--wl-font-size-small);
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  clear: both;
}

.sponsored-listings ul li .information .make-model {
  font-weight: bold;
  color: var(--wl-text-color-seven);
  margin-bottom: 1px;
}

.sponsored-listings ul li .information .price {
  font-size: var(--wl-font-size-x-large);
  line-height: 1.3;
  font-weight: bold;
  color: var(--wl-price-text-color);
  margin-bottom: 5px;
}

.sponsored-listings ul li .information .location {
  color: var(--wl-text-color-five);
  margin-bottom: 5px;
}

.sponsored-listings ul li .information .seller-name {
  color: var(--wl-text-color-seven);
}

.sponsored-listings ul li:nth-child(n + 3) {
  display: none;
}

.sponsored-listings .preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: var(--wl-preload-background-image);
}

.sponsored-listings .preloader.hidden {
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}

@media screen and (min-width: 768px) {
  .sponsored-listings ul {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  .sponsored-listings ul li:nth-child(n + 3) {
    display: block;
  }
}
