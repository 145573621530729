.detail-description {
  max-height: 160px;
  overflow: hidden;
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-x-large);
  color: var(--wl-text-color-two);
  transition: max-height 1s ease;
  position: relative;
}

.detail-description.small {
  max-height: 80px;
}

.detail-description p {
  margin-top: 0;
}

.detail-description.overlap.less {
  max-height: 100%;
  padding-bottom: 20px;
}

.detail-description .more-less {
  display: none;
  position: absolute;
  bottom: -6px;
  text-align: right;
  width: 100%;
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-x-large);
  color: var(--wl-text-color-link);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, var(--wl-content-background-color-default) 100%);
}

.detail-description.small .more-less {
  text-align: left;
}

.detail-description .more-less .toggle{
  cursor:pointer;
  outline: 0;
  background: var(--wl-content-background-color-default);
}

.detail-description.overlap .more-less {
  display:block;
}
