.detail-data-table .datatable-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.detail-data-table .datatable-category {
  padding: 0;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  display: block;
}

.detail-data-table .datatable-category .sub-title {
  color: var(--wl-text-color-one);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-xx-large);
  margin-bottom: 5px;
  font-weight: bold;
  font-style:italic;
}

.detail-data-table .datatable-category .datatable-section {
  padding-left: 20px;
}

.detail-data-table .datatable-item {
  display: flex;
}

.detail-data-table .datatable-title{
  color: var(--wl-text-color-one);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-xx-large);
  min-width: 190px;
  flex-shrink: 0;
}

.detail-data-table.measurements .datatable-title {
  color: var(--wl-text-color-one);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-xx-large);
  min-width: 195px;
  flex-shrink: 0;
}

.detail-data-table.features .datatable-title {
  color: var(--wl-text-color-one);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-xx-large);
  min-width: 195px;
  flex-shrink: 0;
}

.detail-data-table .datatable-title:after {
  content: ':';
}

.detail-data-table .datatable-value {
  color: var(--wl-text-color-one);
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-xx-large);
  text-transform: capitalize;
}


@media screen and (min-width:768px) {

  .detail-data-table .datatable-item {
    max-width: 50%;
  }

  .detail-data-table .datatable-section {
    max-height: 170px;
  }

  /* add extra depth to the table if items greater than 10 */
  .detail-data-table .datatable-section.data-table__large {
    max-height: 170px;
  }

  .detail-data-table .datatable-section.data-table__xlarge {
    max-height: 350px;
  }

  .detail-data-table .datatable-value {
    margin-right: 10px;
  }

}

@media screen and (min-width:1024px) {
  .detail-data-table .datatable-section.data-table__large {
    max-height: 200px;
  }

  .detail-data-table .datatable-section.data-table__xlarge {
    max-height: 300px;
  }
}

/* -- ANA-1582 -- */
.detail-data-table.features .datatable-section {
  max-height: 100% !important;
}

.detail-data-table.features .datatable-item {
  max-width: 100%;
}
