.dealer-contact-area {
  padding: 16px;
}
.dealer-contact-area.title {
  padding: 0; 
}

@media screen and (min-width: 769px) {
  .dealer-contact-area {
    display: flex;
    justify-content: flex-end;
    margin-left: 316px;
    padding: 0px 12px 0 16px; 
  }
  .dealer-contact-area.title {
    display: flex;
    justify-content: flex-end;
    padding: 0; 
    margin: 0 0 0 8px;
  }
}