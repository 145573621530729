.tool-set-radio-group {
  display: flex;
  border: 1px solid var(--wl-border-color-default);
  border-radius: 5px;
  flex-grow: 1;
}

.filter-v2 .tool-set-radio-group {
  background: var(--tpp-srp-filters-tool-set-background-color);
  border-radius: 100px;
  overflow: hidden;
  padding: 3px 4px;
  height: 40px;
  box-sizing: border-box;
}

.tool-set-radio-item {
  flex-grow: 1;
}

.tool-set-radio-item label {
  padding: 6px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.tool-set-radio-item:first-of-type label {
  border-radius: 4px 0 0 4px;
}

.tool-set-radio-item:last-of-type label {
  border-radius: 0 4px 4px 0;
}

.tool-set-radio-item.checked label {
  color: var(--wl-text-color-four);
  background: var(--wl-background-color-three);
}

.tool-set-radio-item + .tool-set-radio-item {
  border-left: 1px solid var(--wl-border-color-default);
}

.filter-condition-link {
  color: var(--wl-text-color-five);
  font-size: var(--wl-font-size-small);
  line-height: var(--wl-line-height-small);
}

.filter-v2 .filter-condition-link {
  color: var(--tpp-srp-filters-color);
  font-weight: 500;
}

.filter-v2 .filter-engine-number-link {
  color: var(--tpp-srp-filters-color);
  font-weight: 500;
}

.tool-set-radio-item label a {
  pointer-events: none;
}

.tool-set-radio-item.checked label a {
  color: var(--wl-text-color-four);
}

.filter-v2 .tool-set-radio-item.checked label a {
  color: var(--tpp-srp-filters-color);
}

.filter-v2.three-column-listing .tool-set-radio-item.checked label, 
.filter-v2 .tool-set-radio-item.checked label {
  background: var(--wl-content-background-color-default);
  border-radius: 100px;
  border: unset;
  box-shadow: 3px 1px 4px 0px var(--wl-box-shadow-eight);
  height: 32px;
  color: var(--tpp-srp-filters-color);
  box-sizing: border-box;
}

.filter-v2 .tool-set-radio-item + .tool-set-radio-item {
  border: unset;
}

.filter-v2 .search-filter .radiusTabLabel {
  font-weight: 400;
}

.filter-v2.three-column-listing .tool-set-radio-item label a {
  font-family: 'Roboto', 'Lato', serif;
  font-size: 14px;
  font-weight: 400;
}

.filter-v2.three-column-listing .tool-set-radio-item.checked label a {
  color: var(--tpp-srp-filters-color);
}

.filter-v2 .radiusSearch .tool-set-radio-item label {
  padding: 9px 6px;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;

  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto', 'Lato', serif;
}

.filter-v2 .radiusSearch .tool-set-radio-item label span {
  vertical-align: top;
}
