.search-alerts-container {
  height: 30px;
  margin: 0 12px;
  font-size: var(--wl-font-size-small);
  color: var(--wl-text-color-four);
  background: var(--wl-background-color-three);
  border-radius: 5px;
  overflow: hidden;
}

.filter-v2 .search-alerts-container {
  height: 40px;
  background: var(--tpp-srp-filters-accent-color);
  border-radius: 100px;
}

.mobile-search-filters.filter-v2 .search-alerts-container{
  background: unset;
  border-radius: unset;
  height: auto;
}

@media screen and (max-width: 970px) {
  .search-alerts-container {
    flex-grow: 1;
    height: 44px;
    margin: 0;
    font-weight: 700;
    font-size: var(--wl-font-size-small);
    color: var(--wl-text-color-default);
    border-top: 1px solid var(--wl-border-color-default);
    background: transparent;
    overflow: unset;
  }
}