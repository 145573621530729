.seo-content {
  --seo-content-title-size: var(--wl-font-size-medium);
}

.seo-content {
  display: inline-block;
  margin: 24px 0;
}

.seo-content br {
  border-bottom: 8px solid transparent;
  content: "";
  display: block;
}

.seo-content img {
  float: left;
  height: 100%;
  max-width: 35%;
  padding-right: 10px;
}

.seo-content > div > h1,
.seo-content > div > h2 {
  margin: 0 0 4px 0;
  font-size: var(--seo-content-title-size);
}

.seo-content > div p {
  margin: 0 0 10px 0;
}

@media screen and (max-width: 768px) {
  .seo-content img {
    margin: 0 0 10px 0;
    max-width: 100%;
    width: 100%;
  }
}
