.mobile-filter-anchor {
  position: absolute;
  top: 0;
}

.mobile-search-filters {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

.mobile-search-filters.new-filter-bar {
  position: sticky;
  top: 0;
  z-index: 100;
}

.mobile-search-filters.new-filter-bar:has(.open.tool-set) {
  z-index: 1002;
}

body:has(.mobile-search-filters .tool-set.open) {
 overflow: hidden;
 position: fixed;
 top: 0px;
 left:0px;
 width: 100%;
 height: 100%;
}

.mobile-search-filters .tool-set {
  z-index: 1001;
}

.mobile-search-filters.filter-v2 .tool-set.open .inner .spinner-preloader {
  z-index: 1500;
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  background-color: hsla(0, 0%, 100%, .3);
  pointer-events: none;
}


.mobile-search-filters.filter-v2 .inner > div:not(.collapse-content){
  border-bottom: solid 2px var(--wl-background-color-default);
}

.mobile-search-filters.filter-v2 .inner > div:not(.collapse-content):first-child{
  border-bottom: unset;
}

.mobile-filter-header {
  border-bottom: 20px solid #fff;
  padding: 0 9px;
  box-sizing: border-box;
}

.mobile-search-filters.new-filter-bar {
  border-bottom: 0;
}

.mobile-filter-header .flex-container {
  display: flex;
  align-items: center;
  max-width: 60%;
}

.mobile-search-filters .search-tools > .tool-set > .inner > .collapse-content .header a {
  font-size: var(--wl-font-size-x-small);
}

.mobile-search-filters .filter-header-light .search-tools.sort {
  width: 35%;
}

.mobile-search-filters.new-filter-bar .filter-header-light .search-tools.sort {
  width: auto;
}

.mobile-search-filters .filter-header-light .search-tools.sort .tool-set {
  width: initial;
}

.mobile-search-filters .filter-header-light .search-tools.sort .tool-set .header span.sort-title-header > a {
  font-size: var(--wl-font-size-xx-small);
  font-weight: 600;
}

.mobile-search-filters.new-filter-bar .filter-header-light .search-tools.sort .tool-set .header span.sort-title-header > a {
  color: var(--wl-text-color-sixteen);
}

.mobile-search-filters .filter-header-light .search-tools.sort .collapse-content .header {
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0;
}

.mobile-search-filters .filter-header-light .search-tools.sort .collapse-content .header:before {
  height: 12px;
  margin-top: 2px;
}

.mobile-search-filters.new-filter-bar .filter-header-light .search-tools.sort .collapse-content .header:before {
  height: auto;
  margin-top: 0;
}

.mobile-search-filters .filter-header-light .search-tools.sort .tool-set ul.opts {
  right: 10px;
}

.mobile-filter-header .search-alerts > .search-alerts-button {
  font-size: var(--wl-font-size-xx-small);
  font-weight: 600;
}

.new-filter-bar.mobile-filter-header .search-alerts > .search-alerts-button {
  color: var(--wl-text-color-sixteen);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.new-filter-bar.mobile-filter-header .search-alerts > .search-alerts-button:before {
  /*background: url(../../../../icons/search-icon.svg) no-repeat;*/
  width: 18px;
  height: 18px;
  content: "";
  display: inline-block;
}

.mobile-filter-header .search-alerts > .search-alerts-button:disabled {
  background-color: inherit;
  color: inherit;
  color: #33333380;
}

.mobile-filter-header .search-tools.sort {
  width: 35%;
}

.mobile-filter-header.no-search-alert .filter-button,
.mobile-filter-header.no-search-alert .search-tools.sort {
  width: 50%;
}

.new-filter-bar.mobile-filter-header .search-tools.sort {
  width: auto;
}
.new-filter-bar.mobile-filter-header.no-search-alert .filter-button,
.new-filter-bar.mobile-filter-header.no-search-alert .search-tools.sort {
  width: auto;
}

.mobile-filter-header .search-tools.sort .collapse-content {
  height: 100%;
}

.mobile-filter-header .search-tools.sort .tool-set .header span.sort-title-header > a {
  font-size: var(--wl-font-size-xx-small);
  font-weight: 600;
}

.mobile-search-filters .filter-header-light .filter-button .count,
.mobile-filter-header .search-tools.sort .tool-set .header span.sort-title-header span {
  display: none;
}

.mobile-search-filters.new-filter-bar .filter-header-light .filter-button {
  padding: 0 4px;
}

.mobile-filter-header .search-tools.sort .tool-set ul.opts {
  right: 10px;
  bottom: 35px;
  top: initial;
  filter: drop-shadow(3px 0 2px rgba(0,0,0,.15));
  box-shadow: none;
}

.new-filter-bar .mobile-filter-header .search-tools.sort .tool-set ul.opts {
  bottom: initial;
}

.mobile-filter-header .search-tools.sort .tool-set ul.opts li .custom-option,
.mobile-filter-header .search-tools.sort .tool-set ul.opts li label {
  height: 25px;
  line-height: 25px;
  padding: 3px 20px;;
  font-size: var(--wl-font-size-x-small);
}

.mobile-search-filters .tool-set .btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mobile-filter-header .search-alerts-container {
  height: 44px;
  width: 40%;
  border-top: none;
  border-right: 1px solid var(--wl-border-color-default);
  border-radius: 0;
  padding: 0 4px;
  background: var(--wl-content-background-color-default);
}

.new-filter-bar.mobile-filter-header .search-alerts-container {
  width: auto;
}

.mobile-filter-header .search-tools.sort .tool-set .header span.sort-title-header > a {
  font-size: var(--wl-font-size-xx-small);
}

.mobile-search-filters .btn-container .search-alerts-container {
  min-width: 48%;
  height: 46px;
  border: none;
  margin-right: 10px;
}

.filter-v2.mobile-search-filters .btn-container .search-alerts-container {
  height: 40px;
}

.mobile-search-filters .btn-container .search-alerts-container .search-alerts-button,
.mobile-search-filters .tool-set .buttons .btn-action {
  height: 46px;
  border-radius: 5px;
  font-weight: 600;
  font-size: var(--wl-font-size-small);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.filter-v2.mobile-search-filters .btn-container .search-alerts-container .search-alerts-button,
.filter-v2.mobile-search-filters .tool-set .buttons .btn-action {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  height: 40px;
  border-radius: 20px;
  box-sizing: border-box;
}

.mobile-search-filters .tool-set .buttons .btn-action.hidden {
  display: none;
}

.mobile-search-filters .tool-set .btn-action+.btn-action {
  position: relative;
}

.mobile-search-filters .btn-container .search-alerts-container .search-alerts-button {
  color: var(--wl-text-color-four);
  background: var(--wl-background-color-three);
}

.filter-v2.mobile-search-filters .btn-container .search-alerts-container .search-alerts-button {
  background: white;
  border: 2px solid var(--wl-border-color-twelve);
  color: var(--wl-border-color-twelve);
}

.mobile-search-filters .btn-container .search-alerts-container .search-alerts-button:disabled {
  opacity: .5;
}

.mobile-search-filters .buttons {
  height: auto;
  padding: 15px 15px 26px;
  filter: drop-shadow(0 -3px 2px rgba(0,0,0,.15));
}

.filter-v2.mobile-search-filters .buttons {
  box-shadow: 0px -3px 10px 5px var(--wl-box-shadow-nine);
  padding: 2px 15px;
}

.mobile-search-filters .tool-set,
.mobile-search-filters .tool-set .inner {
  padding: 0 0 94px 0;
}

.filter-v2.mobile-search-filters .tool-set.open {
  padding: 0px;
}

.mobile-search-filters > .tool-set.open > .inner {
  padding: 15px 15px 0 15px;
  box-sizing: border-box;
}

.filter-v2.mobile-search-filters > .tool-set > .inner {
  border: solid 4px var(--wl-border-color-fourteen);

  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
}

.filter-v2.mobile-search-filters > .tool-set.open > .inner {
  height: calc(100% - 116px);
  padding: 0 0 0px 0;
}

.mobile-search-filters .tool-set .buttons .btn-container {
  height: 56px;
}

.mobile-search-filters .tool-set .pill-box {
  width: 100%;
  height: 50px;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  filter: drop-shadow(0 3px 2px rgba(0,0,0,.15));
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px;
  z-index: 10;
  &+div {
    margin-top: 35px;
  }
}

.filter-v2.mobile-search-filters .tool-set .pill-box {
  top: 56px;
  &+div {
    margin-top: 45px;
  }
}

.mobile-search-filters .tool-set .pill-box .scroller {
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 100px;
  display: flex;
}

.mobile-search-filters .tool-set .pill-box .scroller a {
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: 0;
  padding: 7px 10px;
  font-size: var(--wl-font-size-xxx-small);
  line-height: initial;
}

.filter-v2.mobile-search-filters .tool-set .pill-box .scroller a {
  font-size: 12px;
  font-family: 'Roboto', 'Lato', Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 16px;
}

.mobile-search-filters .tool-set {
  left: initial;
  top: 110%;
  transition: top .3s ease;
}

.mobile-search-filters .tool-set.open {
  top: 0;
  transform: none;
}

.mobile-search-filters #tool-set-no-padding .tool-set-header {
  z-index: 1002;
  position: relative;
}

.filter-v2.mobile-search-filters .tool-set.open {
  padding-top: 0px;
}

@media screen and (max-width: 969px) {
  .search-tools.sort .tool-set .header span.sort-title-header > .toggle-btn > span:first-of-type {
    display: none;
  }
}

@media screen and (min-width: 970px) {
  .mobile-filter-header {
    display: none;
  }

  .tool-set .pill-box .scroller {
    display: flex;
    flex-wrap: wrap;
  }
}
