/* BASE font-size: 15px */

.payment-calculator .collapse-content-details.open>.collapsible {
  overflow: visible;
}

/* Desktop */
.private-calc-fin-calculator-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.private-calc-fin-calculator-sub-container {
  width: 100%;
}

.private-calc-fin-calculator-body {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 12px;
  border: 1px solid #EDEDED;
}

.private-calc-fin-calculator-form .calc-free-text-input-error{
  margin: 0;
}

/* Form */
.private-calc-fin-calculator-form {
  box-sizing: border-box;
  width: 50%;
  background-color: #F5F9FF;
  padding: 16px;
  border-radius: 12px 0 0 12px;
}

.private-calc-fin-calculator-form .form-fieldset {
  display: flex;
  flex-direction: column;
  gap:16px;
  border: none;
  padding-top: 0;
}

.private-calc-fin-calculator-container .calc-calculator-summary .calc-monthly-payment-container {
  color: #404040;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: baseline;
  border-radius: 12px;
  border: 1px solid #F5F5F5;
  border: 1px solid var(--Neutral-neutral-30, #EDEDED);
  background: var(--Primary_Action-Surface, #F5F9FF);
  margin-bottom: 18px;
}

.private-calc-fin-calculator-container .calc-calculator-summary .calc-monthly-payment-container .per-month{
  color: var(--Neutral-neutral-90, #404040);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.private-calc-fin-calculator-container .calc-calculator-summary .calc-montlhy-payment-amount {
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--Primary, #2566B0);
  font-family: "Roboto", sans-serif;
}

.private-calc-fin-calculator-container .calc-calculator-summary .calc-montlhy-payment-title {
  color: #0A0A0A;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.private-calc-fin-calculator-form .calc-form-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.private-calc-fin-calculator-form .calc-form-label {
  overflow: hidden;
  color: #0A0A0A;
  text-overflow: ellipsis;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  white-space: nowrap;
  margin-bottom: -8px;
}

.private-calc-fin-calculator-form .calc-form-data.input {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #F5F5F5;
  background: #FFF;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10) inset;
  font-size: 14px;
  outline-color: #2566B0;
  color: #0A0A0A;
  font-style: normal;
  font-weight: 400;
  line-height: 24px
}

.private-calc-fin-calculator-form .calc-form-loan-term-container,
.private-calc-fin-calculator-form .calc-form-down-payment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.private-calc-fin-calculator-form .calc-form-title{
  flex: 1 0 0;
  color: #0A0A0A;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.private-calc-fin-calculator-form .calc-form-instructions {
  color: var(--bt-color-neutral-100);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

.private-calc-fin-calculator-form .calc-boat-loans-link {
  text-decoration: underline;
  color: var(--bt-color-secondary_action-main);
}

/* Summary */

.private-calc-fin-calculator-container .calc-calculator-summary {
  display: flex;
  height: auto;
  width: 50%;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 0 12px 12px 0;
  padding: 20px 32px 24px 32px;
  flex-direction: column;
  align-items: center;
}

.private-calc-fin-calculator-container .calc-calculator-summary-upper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.private-calc-fin-calculator-container .calc-calculator-summary-upper .calc-calculator-loan-amount {
  text-align: center;
}

.private-calc-fin-calculator-container .calc-calculator-summary-lower-title {
  color: #0A0A0A;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.private-calc-fin-calculator-container .calc-calculator-summary-lower-text {
  color: #0A0A0A;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
}

.blue-checkmark {
  margin-right: 10px
}

.blue-checkmark img {
  width: 19px;
  height: 19px;
}

.blue-checkmark.last img {
  width: 24px;
  height: 19px;
}

.private-calc-fin-calculator-container .calc-loan-amount-label {
  color: var(--Neutral-neutral-90, #404040);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.private-calc-fin-calculator-container .calc-loan-amount-value {
  color: var(--Neutral-neutral-90, #404040);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.calc-calculator-summary-lower-title {
  margin-top: 15px
}

.private-calc-fin-calculator-container .calc-calculator-summary-lower {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 16px;
  align-self: stretch;
  padding: 0 16px;
  border-top: 1px solid #CCC;
}

.private-calc-fin-calculator-container .summary-title{
  color: #404040;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}

.private-calc-fin-calculator-container .summary-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  padding: 0 16px;
}

.private-calc-fin-calculator-container .summary-step-title {
  display: flex;
  width: 250px;
  justify-content: start;
  align-items: center;
  margin-left: 30px;
}

.private-calc-fin-calculator-container .summary-step-name {
  color:  #404040;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px;
}

.private-calc-fin-calculator-container .hidden {
  display: none;
}

.private-calc-fin-calculator-container .summary-step-number {
  display: flex;
  padding: 4px 11px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 2px solid #404040;
  color: #404040;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;


}

.private-calc-fin-calculator-container .summary-step-text{
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 250px;
  list-style: none;
  margin-left: 75px;
  padding: 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
}

.private-calc-fin-calculator-container .summary-step-text li {
  text-align: left;
  display: flex;
  width: 200px;
}

.private-calc-fin-calculator-container .summary-step-text li:before {
  content: " ";
  display: block;
  width: 4px;
  height: 10px;
  border: solid #00AC91;
  border-width: 0 .2em .2em 0;
  transform: rotate(45deg);
  margin-right: 15px;
}

.private-calc-fin-calculator-container .strong-text{
  font-weight: 700;
}

.private-calc-fin-calculator-container .summary-button-container{
  width: -webkit-fill-available;
  margin: 0 16px;
}

.private-calc-fin-calculator-container .get-started-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  text-rendering: geometricprecision;
  cursor: pointer;
  border-radius: 100px;
  transition: 0.3s;
  transition-property: background, font-size;
  padding: 8px 20px;
  width: 300px;
  height: 46px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 2px solid #2566B0 !important;
  color: #2566B0 !important;
  background: #fff !important;
}

.private-calc-fin-calculator-container .get-started-button:hover {
  background: #fff !important;
}

.private-calc-fin-calculator-container .get-started-button:disabled{
  background: #a7e7dd;
}

.send-request-button:active:hover {
  font-size: 14.5px;
}

.private-calc-fin-calculator-form .calc-form-data.input#interest-rate {
  width: 100%;
  height: 2.66rem;
  border-radius: 0.26rem;
  margin-bottom: 5px;
  margin-top: -7px;
}

.private-calc-fin-calculator-form .loan-term,
.private-calc-fin-calculator-form .down-payment {
  width: 37%;
}

.calc-form-label.loan-amount-or-monthly-payment {
  margin-top: 1rem;
}
.private-calc-fin-calculator-form .calc-free-text-input-error.interest-rate {
  margin-top: 16.75rem;
  margin-left: 0.5%;
}

.calc-fin-calculate-interest-row {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  width: 100%;
}

.calc-percent-sign {
  color: #000;
  font-weight: 600;
  margin-top: 0.7rem;
  margin-left: -1.4rem;
  margin-right: 0.5rem;
  z-index: 1000;
}

.private-calc-fin-calculator-form .calc-or-text-fin {
  overflow: hidden;
  color: #0A0A0A;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  height: 42px;
}

.private-calc-fin-calculator-form .calc-form-data.input#loan-downpayment-percent,
.private-calc-fin-calculator-form .calc-form-data.input#loan-term-years {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  color: black;
  background-image: url(../../icons/DownArrowBlackV2.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 25px;
}

.private-calc-fin-calculator-form .calc-form-data.input#loan-downpayment-percent::-ms-expand,
.private-calc-fin-calculator-form .calc-form-data.input#loan-term-years::-ms-expand {
  display: none;
}

@media only screen and (max-width: 991px) {
  .private-calc-fin-calculator-container {
    height: 100%;
    width: 96%;
    margin: auto;
  }
 
  .private-calc-fin-calculator-form .calc-or-text-fin {
    margin-bottom: 0;
    margin-top: 12px;
  }

  .private-calc-fin-calculator-form .calc-form-data.input#interest-rate {
    margin-bottom: 0;
  }

  .private-calc-fin-calculator-container .calc-calculator-summary .calc-montlhy-payment-title {
    font-size: 20px;
  }
  
  .private-calc-fin-calculator-form .calc-free-text-input-error.interest-rate {
    margin-top: 16.75rem;
    margin-left: 0.5%;
  }

  .private-calc-fin-calculator-container {
    height: 100%;
  }
  
  .private-calc-fin-calculator-container .calc-or-text{
    width: 31%;
    text-align: center;
  }
  
  .private-calc-fin-calculator-container .calc-calculator-sub-container {
    padding-top: 6rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  
  .private-calc-fin-calculator-container .private-calc-fin-calculator-form {
    width: 100%;
    border-radius: 12px 12px 0 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .private-calc-fin-calculator-container .calc-calculator-summary {
    width: 100%;
    border-radius: 0 0 12px 12px;
  }
  
  .private-calc-fin-calculator-container .calc-calculator-body {
    width: 90%;
    margin-top: 1.73rem;
    height: unset;
    flex-direction: column;
  }

  .private-calc-fin-calculator-container .calc-calculator-summary-lower {
    padding: 0;
  }

  .private-calc-fin-calculator-container .summary-step {
    margin-top: 15px;
  }

  .private-calc-fin-calculator-form .loan-term,
  .private-calc-fin-calculator-form .down-payment {
    width: 45%;
  }
}

/* Lead Form */

.private-calc-fin-calculator-container .inline-prequalified-form {
  width: auto;
  margin: auto;
}

.private-calc-fin-calculator-container .prequalified-instructions,
.private-calc-fin-calculator-container .prequalified-powered-by,
.private-calc-fin-calculator-container .prequalified-privacy-policy {
  display: none;
}

.private-calc-fin-calculator-container .send-request-button,
.private-calc-fin-calculator-container .send-request-button:disabled {
  background: #00AC91;
  height: 46px;
  font-size: 18px;
}

.private-calc-fin-calculator-container .send-request-button:active:hover {
  background: #027967;
  font-size: 19px;
}

/* Toast */
.private-calc-fin-calculator-container .inline-prequalified-success{
  display: flex;
  padding: 11px 12px 0px 12px;
  align-items: flex-start;
  gap: 25px;
  border-radius: 4px;
  border: 1px solid #00AC91;
  background: #FFF;
  color: #404040;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.private-calc-fin-calculator-container .inline-prequalified-success-info{
  margin-bottom: 15px;
  max-width: 260px;
}

.private-calc-fin-calculator-container .services-lead-title{
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}