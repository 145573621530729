.filter-header-container {
  height: fit-content;
}

.filter-header {
  font-size: var(--wl-font-size-xxx-small);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0);
  transition: box-shadow 300ms, background-color 300ms;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.new-filter-bar .filter-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.new-filter-bar .mobile-filter-header.no-search-alert .filter-header {
  grid-template-columns: 1fr 1fr;
}

.filter-header .filter-options {
  flex-grow: 1;
}

.filter-header .filter-options > a {
  float: right;
  margin-left: 20px;
  color: var(--wl-text-color-link);
  font-weight: bold;
}

.filter-header .filter-options > a:hover {
  text-decoration: underline;
}

.filter-header .count {
  display: inline-block;
  height: 16px;
  width: 16px;
  font-size: var(--wl-font-size-xxx-small);
  background: var(--wl-background-color-four);
  text-align: center;
  line-height: var(--wl-line-height-x-large);
  border-radius: 50%;
  color: var(--wl-text-color-sixteen);
  top: -1px;
  position: relative;
  margin-left: 2px;
}

.filter-header.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: fit-content;
  background: var(--wl-content-background-color-default);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.3);
  left: 0;
}

.filter-header.sticky .filter-button {
  margin-left: 0;
}

.filter-header.sticky .filter-options {
  margin-right: 9px;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--wl-text-color-four);
  font-size: var(--wl-font-size-xx-small);
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  background: var(--wl-background-color-three);
  border: 0 none;
  position: relative;
  padding: 0;
  outline: 0;
  width: 25%;
}

.new-filter-bar .filter-button {
  width: auto;
}

.filter-button:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 8px solid var(--wl-background-color-three);
  position: absolute;
  top: 0;
  right: -7px;
}

@media screen and (min-width: 970px) {
  .filter-header .filter-options {
    margin-right: 9px;
  }
  .filter-header.sticky {
    left: auto;
    width: 300px;
    z-index: 1200;
    top: 0;
  }
}
