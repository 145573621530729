.location-map-wrapper {
  position: relative;
}

.hidden-location-map-wrapper {
  display: none;
}

.location-map-wrapper .location-map,
.location-map .spinner-preloader {
  width: 100%;
  height: 200px;
  position: relative;
}

.location-map-wrapper .address {
  text-align: center;
}

.location-map-wrapper .close-map-modal {
  position: absolute;
  right: 11px;
  top: -22px;
  cursor: pointer;
  background: url('../../icons/ModalCloseButton.svg') no-repeat 50%;
  padding: 9px;
  border: 0;
}

.calculate-route {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
}

.calculate-route .calculate-route-button {
  width: 45%;
  color: var(--wl-text-color-four);
  background: var( --wl-background-color-six);
  border: 1px solid var( --wl-background-color-six);
  box-sizing: border-box;
}

.location-map-wrapper .modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: var(--wl-background-color-ten);
  z-index: 1000;
}

.location-map-wrapper .modal-main {
  position:fixed;
  background: var(--wl-content-background-color-default);
  width: 80%;
  height: 70%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@media screen and (min-width: 768px) {

  .calculate-route .calculate-route-button {
    width: auto;
  }

  .modal-main {
    height: 80%;
  }

}
