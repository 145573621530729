.filter-header-light {
  border-top: 1px solid var(--wl-border-color-default);
  border-bottom: 1px solid var(--wl-border-color-default);
  background-color: var(--wl-content-background-color-default);
  transition: none;
  margin-left: -9px;
  margin-right: -9px;
}

.filter-header-light.sticky {
  background-color: var(--wl-content-background-color-default);
  box-shadow: none;
  height: 44px;
  margin-left: 0;
  margin-right: 0;
}

.filter-header-light .filter-button {
  width: 25%;
  font-style: normal;
  font-weight: 600;
  font-size: var(--wl-font-size-xx-small);
  text-transform: capitalize;
  background: var(--wl-content-background-color-default);
  color: var(--wl-text-color-default);
  border-right: 1px solid var(--wl-border-color-default);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-filter-bar .filter-header-light .filter-button {
  width: auto;
  color: var(--tpp-srp-filters-mobile-filter-header-color);
}

.filter-header-light .filter-button:before {
  background: url(./Filter.svg) no-repeat;
  width: 16px;
  height: 16px;
  content: "";
  display: inline-block;
  margin-right: 4px;
  transform: scale(.65);
  opacity: .8;
  margin-top: -1px;
}

.new-filter-bar .filter-header-light .filter-button:before {
  background: url(../../../../../icons/filter-icon.svg) no-repeat;
  width: 20px;
  height: 14px;
  transform: none;
  opacity: 1;
}

.filter-header-light .filter-button .count {
  background: var(--wl-background-color-three);
  height: 20px;
  width: 20px;
  top: -1px;
  line-height: var(--wl-line-height-xxx-large);
  font-size: var(--wl-font-size-xx-small);
  margin-left: 4px;
}

.filter-header-light .filter-button:after {
  display: none;
  width: 50%;
}

.filter-header-light .search-tools.sort {
  width: 50%;
  background-color: var(--wl-content-background-color-default);
  padding: 0;
  position: relative;
  text-align: center;
}

.new-filter-bar .filter-header-light .search-tools.sort {
  width: auto;
}

.filter-header-light .search-tools.sort .tool-set {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.filter-header-light .search-tools.sort .tool-set .inner {
	float: none;
}

.filter-header-light .search-tools.sort .tool-set h2 {
  line-height: var(--wl-line-height-default);
  display: block;
  text-align: left;
  padding: 0;
}

.filter-header-light .search-tools.sort .tool-set h2:before {
  color: var(--wl-text-color-default);
  font-size: var(--wl-font-size-small);
  font-weight: bold;
  display: block;
  line-height: var(--wl-line-height-small);
}

.filter-header-light .search-tools.sort .tool-set .header span.sort-title-header > a {
  color: var(--wl-text-color-default);
  font-size: var(--wl-font-size-small);
  font-weight: bold;
  display: block;
  line-height: var(--wl-line-height-small);
}

.new-filter-bar .filter-header-light .search-tools.sort .tool-set .header span.sort-title-header > a > span {
  color: var(--wl-text-color-sixteen);
}

.filter-header-light .search-tools.sort .collapse-content .header {
  display: flex;
  align-items: center;
}

.filter-header-light .search-tools.sort .collapse-content .header:before {
  background: url(./Sort.svg) no-repeat;
  width: 12px;
  height: 16px;
  content: "";
  display: inline-block;
  margin-right: 8px;
  flex-shrink: 0;
}

.new-filter-bar .filter-header-light .search-tools.sort .collapse-content .header:before {
  background: url(../../../../../icons/sort-icon.svg) no-repeat;
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.filter-header-light .search-tools.sort .collapse-content .header a .icon {
  display: none !important;
}

.filter-header-light .search-tools.sort .tool-set ul.opts {
  right: -10px;
}

@media screen and (min-width: 768px) {
  .filter-header-light .search-tools.sort .tool-set ul.opts {
    right: auto;
    left: -5px;
  }
}
