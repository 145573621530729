.pill-box {
    padding: 15px 11px;
    display: flex;
    background-color: var(--wl-content-background-color-default);
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
}

.pill-box .no-facets {
    font-size: var(--wl-font-size-xx-small);
    line-height: var(--wl-line-height-medium);
}

.pill-box .pill-box-clear,
.pill-box .pill-box-item {
    margin-bottom: 8px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: var(--wl-font-size-small);
}

.filter-v2 .pill-box .pill-box-clear,
.filter-v2 .pill-box .pill-box-item {
    padding: 4px 8px;
    color: var(--wl-text-color-one);
    border-radius: 100px;
}

.pill-box .pill-box-clear {
    padding: 5px 9px;
    color: var(--wl-text-color-four);
    background-color: var(--wl-background-color-ten);
}

.filter-v2 .pill-box .pill-box-clear {
    background: var(--wl-background-color-twenty-two);
    color: var(--wl-text-color-four);
}

.pill-box .pill-box-item {
    padding: 5px 9px;
    color: var(--wl-text-color-one);
    background-color: var(--wl-background-color-default);
}

.filter-v2 .pill-box .pill-box-item {
    background: var(--tpp-srp-filters-tool-set-background-color);
    color: var(--wl-text-color-one);
}

.pill-box .pill-box-item.capitalize {
    text-transform: capitalize;
}

.pill-box .pill-box-delete {
    margin-left: 7.5px;
    width: 8px;
    height: 8px;
    stroke: var(--wl-text-color-one);
    fill: var(--wl-background-color-default);
}

.pill-box .filter-tag-item:hover {
    cursor: pointer;
}
