.type-chooser {
  display: flex;
  justify-content: space-between;
  padding: 16px 0 8px 0;
}

.tab {
  background: var(--wl-background-color-default);
  border-radius: 5px;
  color: var(--wl-text-color-default);
  cursor: pointer;
  padding: 4px 12px;
  text-align: center;
  width: 40%;
}

.tab.active {
  background-color: var(--wl-background-color-four);
  color: var(--wl-text-color-four);
  font-weight: bold;
}

