.search-alerts-overlay,
.search-alerts-modal {
  --search-alerts-modal-bg: var(--wl-background-color-ten);
  --search-alerts-modal-window-bg: var(--wl-content-background-color-default);
  --search-alerts-modal-submit-button-bg: var(--wl-background-color-fifteen);
  --search-alerts-modal-submit-button-color: var(--wl-text-color-sixteen);
  --search-alerts-modal-input-border-color: #bdc3c7;
  --search-alerts-modal-placeholder-color: var(--wl-text-color-twelve);
  --search-alerts-modal-terms-color: var(--wl-text-color-twelve);
}

.search-alerts-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: var(--search-alerts-modal-bg);
}

.search-alerts-modal {
  width: 95%;
  max-width: 542px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: var(--search-alerts-modal-window-bg);
  transform: translate(-50%, -50%);
}

.search-alerts-modal:focus {
  outline: none;
  box-shadow: none;
}

.search-alerts-modal .container {
  padding: 16px 16px 14px 16px;
}

.search-alerts-modal .container .header {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}

.search-alerts-modal .container .header .title {
  font-size: var(--wl-font-size-medium);
}

.search-alerts-modal .container .header .closeButton {
  height: fit-content;
  cursor: pointer;
  float: right;
  border: none;
  background: none;
  padding: 0;
}

.search-alerts-modal .container .header .closeButton path {
  stroke: #0d0d0d;
  fill: #0d0d0d;
}

.search-alerts-modal .container .content {
  background: transparent;
}

.search-alerts-modal .container .content label {
  display: none;
}

.search-alerts-modal .container .content .flexbox {
  display: flex;
  height: 36px;
  margin-bottom: 14px;
  max-width: 700px;
}

.search-alerts-modal .container .content .flexbox input {
  width: 0;
  margin: 0;
  padding: 0 8px;
  border: 1px solid var(--search-alerts-modal-input-border-color);
  border-radius: 5px 0px 0px 5px;
  font-size: var(--wl-font-size-medium);
  flex-grow: 1;
}

.search-alerts-modal .container .content .flexbox input:focus {
  outline: none;
}

.search-alerts-modal .container .content .flexbox input::placeholder {
  font-size: var(--wl-font-size-medium);
  color: var(--search-alerts-modal-placeholder-color);
}

.search-alerts-modal .container .content .flexbox button {
  margin: 0;
  padding: 0px 12.5px 0px 13px;
  font-size: var(--wl-font-size-medium);
  font-weight: bold;
  text-rendering: geometricprecision;
  color: var(--search-alerts-modal-submit-button-color);
  background: var(--search-alerts-modal-submit-button-bg);
  border: none;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

.search-alerts-modal .container .content span {
  font-size: 12px;
}

.search-alerts-modal .container .content a {
  color: var(--search-alerts-modal-terms-color);
  text-decoration: underline;
}

.search-alerts-modal .container .content ul {
  padding-left: 24px;
}

.search-alerts-modal .container .content ul li span {
  font-size: var(--wl-font-size-default);
}

@media screen and (min-width:768px) {
  .search-alerts-modal {
    width: 542px;
  }
}

@media screen and (max-width:500px) {
  @-webkit-keyframes slideIn {
    from { margin-bottom: -260px; }
      to { margin-bottom: 0px; }
  }
  @keyframes slideIn {
    from { margin-bottom: -260px; }
      to { margin-bottom: 0px; }
  }

  .search-alerts-modal {
    position: fixed;
    bottom: 0;
    left: auto;
    top: auto;
    transform: initial;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    -webkit-animation: slideIn 0.4s;
    animation: slideIn 0.4s;
    animation-timing-function: ease;
  }
}