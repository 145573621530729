
.related__content {
  border: 1px solid var(--wl-border-color-default);
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.related__content h2 {
  flex: 0 0 auto;
  color: var(--wl-text-color-default);
  margin: 0 auto;
  font-size: var(--wl-font-size-medium);
  flex-basis: 100%;
  line-height: 1;
  border-bottom: 2px solid var(--wl-border-color-default);
  padding-bottom: 10px;
}

.related__type {
  display: flex;
  flex-flow: row wrap;
}

.related__class {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

.related__make {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

.related__model {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}
.related__model_range {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

.related__condition {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

.related__list_item {
  flex: 1 100%;
  margin: 0 auto;
  display: inline-flex;
  padding: 0;
  font-size: var(--wl-font-size-default);
  line-height: 1;
  border-bottom: 1px solid var(--wl-border-color-default);
  justify-content: space-between;
}

.related__list_item a, .related__list_item div {
  margin: 0;
  padding: 10px 15px;
  align-self: flex-start;
  font-size: var(--wl-font-size-default);
  max-width: 180px;
}

.related__list_item a {
  color: var(--wl-text-color-link);
} 

.related__list_item span {
  padding: 10px 15px;
}

@media screen and (min-width: 970px) {

  .related__list_item a, .related__list_item div {
    max-width: 350px;
  }

}