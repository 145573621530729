.branded-header .banner {
  display: none;
}

.branded-header .banner-logo {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
}

.branded-header .logo-container{
  display: flex;
  position: relative;
  justify-content: center;
  height: 109px;
  padding-top: 16px;
}
.branded-header .logo-container.banner {
  padding-top: 0px;
  height: 52px;
}

.branded-header .logo-container .logo-frame {
  position: absolute;
  bottom: 0px;
  width: 208px;
  padding: 16px;
  box-shadow: 0px 1px 3px 1px var(--wl-box-shadow-three);
  background-color: var(--wl-content-background-color-default);
}

.branded-header .logo-container .logo-frame:empty {
  height: 124px;
  padding: 20px;
}

.branded-header .logo-container .logo-frame .logo {
  height: 96px;
  width: 100%;
  object-fit: contain;
}

.branded-header .information-container {
  margin: 0 15px 15px 16px;
}
.branded-header .information-container h1 {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-large);
}
.branded-header .title-container h1 {
  margin: 0;
}

.branded-header .information-container .party-description {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  white-space: pre-line;
}

.branded-header .title-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 16px;
}
.branded-header .information-container.no-logo {
  margin: 9px 15px 15px 16px;
}
.branded-header .information-container.no-logo h1{
  margin-bottom: 16px;
}
.branded-header .title-container.no-description {
  padding-bottom: 0px;
}

.branded-header img.logo {
  object-fit: contain;
}

@media screen and (min-width: 769px) {

  .branded-header .banner {
    display: flex;
    flex-grow: 1;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
  }

  .branded-header .logo-container {
    justify-content: left;
    height: auto;
    margin-left: 15px;
  }

  .branded-header .information-container {
    margin: 5px 15px;
  }
  .branded-header .logo-container .logo-frame {
    padding: 20px;
    width: 260px;
    position: relative;
  }

  .branded-header .logo-container .logo-frame .logo {
    height: 120px;
    max-height: 120px;
  }

  .branded-header .title-container {
    flex-direction: row;
  }

}

@media screen and (min-width: 970px) {
  .branded-header {
    margin-bottom: 10px;
    border-bottom: 1px solid var(--wl-border-color-default);
  }
}
