.content-head {
  margin-left: auto;
  margin-right: auto;
  max-width: 1133px;
}

.three-column.content-head {
  max-width: 1440px;
  padding-left: 27px;
  padding-right: 27px;
}

.three-column.interesting-info {
  max-width: 1440px;
  padding-left: 27px;
  padding-right: 27px;
  margin-left: auto;
  margin-right: auto;
}

h1.branded-srp-header__title{
  line-height: 24px;
}
.branded-srp-header__description {
  line-height: 20px;
}

.three-column.content-head h1:not(.three-column.content-head h1.branded-srp-header__title) {
  margin: 0;
  padding: 0px 15px 15px;
  color: var(--wl-text-color-default);
  font-size: var(--wl-font-size-xxx-large);
  line-height: var(--wl-line-height-x-large);
}

.three-column .main-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1133px;
  background: var(--wl-content-background-color-default);
  padding: 0 9px;
}

.three-column .main-content:after {
  display: table;
  content: '';
  clear: both;
}

.sticky {
  position: fixed;
  top: 40px;
  z-index: 100;
  height: auto;
  background: var(--wl-content-background-color-default);
}

.sticky-ads {
  position: -webkit-sticky;
  position: sticky;
  top : 41px;
  z-index: 100;
  height: auto;
  background: var(--wl-content-background-color-default);
}

.price-disclaimer {
  margin: 20px 0;
  font-size: 14px;
  color: var(--wl-text-color-five);
}

/*
Three Column Layout listing height defined in ui-library
re-set here to avoid height issues with CLS
*/
.three-column-listing .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  grid-auto-rows: 350px;
  gap: 24px;
  width: 100%;
  min-height: 350px;
  &.sponsored {
    margin-bottom: 24px;
  }

  .grid-listing-link {
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }

  .grid-listing-link.fade-in {
    opacity: 1;
  }

  &.carousel {
    min-height: 300px;
    margin: 12px 0;
    > div {
      grid-column: 1 / -1;
    }
  }
}
.three-column-listing {
  .listing-block-1, .listing-block-2, .listing-block-3, .listing-block-4, .listing-block-5, .listing-block-6 {
    height: auto;
  }
  .listing-block-0 {
    min-height: auto;
    height: auto;
  }
}

.three-column-listing .container > a {
  overflow: hidden;
  color: #404040;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
}
.three-column-listing .container.extra-sponsored,
.three-column-listing .container[data-testid="3-col-listings-container"].top-listings-block {
  margin-bottom: 24px;
}

.three-column-listing.main-content {
  display: flex;
  max-width: 1440px;
  padding-left: 27px;
  padding-right: 27px;
  margin-top: 12px;
}

.three-column-right-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.three-column-right-content .mobile-box {
  display: none;
}

.three-column-listing #left-content {
  padding-left: 0;
}

.main-content[data-variant="v2"] .alert {
  margin: 0px 0px 10px 0px;
}

.toggle-boats-engines {
  border-bottom: 1px solid #e5e7eb;;
}
.toggle-boats-engines .type-chooser {
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  gap: 10px;
  padding: 0;
}
.toggle-boats-engines .type-chooser .tab{
  font-family: Roboto;
  background: white;
  color: #303030;
  font-size: 16px;
  font-weight: 400;

  border-radius: 0;
  width: unset;

  display: flex; 
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1 0 0;
}
.toggle-boats-engines .type-chooser .tab.active{
  font-weight: 500;
  border-bottom: 2px solid #303030;
}
.branded-srp-header__logo {
  flex-shrink: 0;
  padding: 10px 30px;
  box-sizing: border-box;
}
/* Tablet only */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .main-content[data-variant="v2"] img[src*="default-listing-image"] {
    object-fit: contain;
  }
}

/* Tablet and above */
@media screen and (min-width: 768px) {
  .three-column .main-content {
    padding: 0 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .three-column-listing .container {
    grid-auto-rows: unset;
  }
}

/* Desktop */
/* we enforce absolute width for three column layout on big screen */
@media screen and (min-width: 1440px) {
  .three-column-right-content {
    width: 1110px;
    div.container.listing-block-0 {
      height: auto;
      min-height: auto;
    }

  }
  .three-column-right-content div.container {
    width: 1110px;
    min-height: 350px;
  }
}

/* Small laptop and above */
@media screen and (min-width: 970px) {
  .three-column .main-content {
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }
  .branded-srp-header, .toggle-boats-engines, .broker-card {
    margin-left: 18px;
  }
  .toggle-boats-engines .type-chooser {
    width: 340px;
  }
  .main-content #right-content {
    flex-grow: 1;
    padding-right: 15px;
  }

  .main-content #left-content {
    flex-shrink: 0;
    width: 300px;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }

  .filter-v2.main-content #left-content {
    width: 340px;
  }

  .sticky-mobile-ad {
    display: none;
  }
  /*
  Listing height is set in library to 350px.
  .three-column-listing .container sets the space between the listings
  to 24px, so there is 24px of space between each row.
  This is a hack to try to solve an issue with CLS without a huge refactor.
  In case that refactor is finally done, this should be removed.
  */
  .three-column-listing {
    .listing-block-1 {
      height: 350px;
    }

    .listing-block-2 {
      height: 724px;
    }

    .listing-block-3 {
      height: 1098px;
    }

    .listing-block-4 {
      height: 1472px;
    }

    .listing-block-5 {
      height: 1846px;
    }

    .listing-block-6 {
      height: 2220px;
    }
  }
}

/* Small laptop and below */
@media screen and (max-width: 969px) {
  .three-column.interesting-info {
    padding: 0 10px;
  }

  #div-gpt-ad-mobile-leaderboard-custom {
    min-height: 50px;
  }
  .sticky-mobile-ad {
    position: sticky;
    top: 0;
    z-index: 11;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    min-height: 50px;
  }
}

.ws-narrative-wrapper {
  display: block;
  max-width: 1133px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem 2rem;
  box-sizing: border-box;
  background-color: white;
  padding-top: 2rem;
}

.ws-narrative-wrapper h3 {
  color: #0f4d77;
  margin-top: 0;
}

.ws-narrative-wrapper p {
  margin-top: 0;
  margin-bottom: 30px;
}

.three-column .information-wrapper {
  display: block;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
}

.three-column .information-wrapper-position-bottom {
  background-color: #f7f7f7;
  padding-top: 2rem;
}

.three-column .interesting-information-image {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.interesting-information-title {
  color: #2c3d4d;
  margin-top: 0;
}

.interesting-information-link, .interesting-information-description a {
  cursor: pointer;
  color: var(--wl-text-color-link);
}

.interesting-information-description p {
  margin-top: 0;
}

.three-column-listing .column-ads {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  height: 350px;
}

.three-column-listing .column-ads > div {
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0;
  padding: 0;
  background: #FFFFFF;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-column-listing .column-ads.tablet-ads {
  grid-column: 1 / span 3;
  gap: 24px;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  border: 0;
  height: unset;
  overflow: auto;
}

.three-column-listing .column-ads.tablet-ads > div {
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.three-column-listing .column-ads.tablet-ads > div > a {
  height: 100%;
}
.three-column-listing .column-ads.tablet-ads > div.native-ad > a > div {
  height: 100%;
}

.three-column-listing .column-ads.tablet-ads > div.regular-ad > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 1120px) {
  .three-column-listing .column-ads > div.native-ad > a {
    height: 350px;
  }
  .three-column-listing .column-ads > div.native-ad > a > div {
    height: 350px;
  }
}

/* Tablet and below */
@media screen and (max-width: 768px) {
  .information-container {
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
  }

  .three-column-listing .container {
    grid-template-columns: minmax(0, 1fr);
  }

  .three-column-listing .column-ads.tablet-ads {
    grid-column: initial;
    flex-direction: column;
  }

  .three-column-listing #left-content {
    display: none;
  }

  .three-column-listing .style-module_carousel__sADnc.style-module_cta__1dw5G button[data-role="next"],
  .three-column-listing .style-module_carousel__sADnc.style-module_cta__1dw5G button[data-role="prev"] {
    padding: 12px;
    border-radius: 22px;
  }

  .three-column-listing .column-ads {
    display: none;
  }

  .three-column-right-content .mobile-box {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
    width: 100%;
  }
  .three-column-right-content .mobile-box a {
    width: 100%;
  }

  /*
    Hard to get mobile view from ad-butler without an absolute height.
    In case they change the template, this will need to be updated.
  */
  .three-column-right-content .mobile-box  .ad-column.native-ad a div img.ad-nine-image {
    height: 200px;
  }

  .three-column-right-content .mobile-box .regular-ad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    text-align: center;
  }
}

.information-wrapper-position-top {
  display: none;
}

.srp-videos {
  height: auto;
}

@media screen and (min-width: 768px) {
  .information-wrapper-position-top {
    display: block;
  }
}

[class*="style-module_listingSponsored"] [class*="style-module_label"],
.container.sponsored a ul li span label, .container.extra-sponsored a ul li span label, a.first-featured ul li span label{
  font-size: 12px;
}


/* Mobile Search Filter - Three Column layout */
.new-filter-bar .mobile-filter-header .mobile-search-filters {
  position: sticky;
  top: 0;
}

.mobile-search-filters.new-filter-bar .mobile-filter-header {
  border-bottom: 0;
}

.mobile-search-filters.new-filter-bar  .filter-header-light .search-tools.sort {
  width: auto;
}

.mobile-search-filters.new-filter-bar .filter-header-light .search-tools.sort .tool-set .header span.sort-title-header > a {
  color: var(--tpp-srp-filters-mobile-filter-header-color);
}

.mobile-search-filters.new-filter-bar  .filter-header-light .search-tools.sort .collapse-content .header:before {
  height: 14px;
  margin-top: 0;
}

.new-filter-bar .mobile-filter-header .search-alerts > .search-alerts-button {
  color: var(--tpp-srp-filters-mobile-filter-header-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.new-filter-bar .mobile-filter-header .search-alerts > .search-alerts-button:before {
  background: url(../../../icons/search-icon.svg) no-repeat;
  width: 18px;
  height: 18px;
  content: "";
  display: inline-block;
}

.new-filter-bar .mobile-filter-header .search-tools.sort {
  width: auto;
}
.new-filter-bar .mobile-filter-header.no-search-alert .filter-button,
.new-filter-bar .mobile-filter-header.no-search-alert .search-tools.sort {
  width: auto;
}

.mobile-search-filters.new-filter-bar .filter-header-light .filter-button {
  padding: 0 4px;
}

.new-filter-bar .mobile-filter-header .search-tools.sort .tool-set ul.opts {
  bottom: inherit;
}

.new-filter-bar .mobile-filter-header .search-alerts-container {
  width: auto;
}

.three-column-listing .sticky-ad {
  position: fixed;
  top: 44px;
  left: 0;
  height: auto;
  background: var(--wl-content-background-color-default);
  transition: top .2s linear;
  width: 100%;
  text-align: center;
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
  padding-top: 8px;
  padding-bottom: 8px;
}

.three-column-listing .sticky-ad.wide-ad {
  left: auto;
}

/* Tablet and above */
@media screen and (min-width: 768px) {
  #boat-search_banner-top-container {
    margin: 10px 0;
  }
}

#ad-boat-search_banner-top {
  display: grid;
  place-items: center;
  min-height: 100%;
}
