.results-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
}

.results-header:after {
  display: table;
  content: '';
  clear: both;
}

.results-header .results-count {
  color: var(--wl-text-color-five);
  font-size: var(--wl-font-size-x-small);
  line-height: var(--wl-line-height-xxx-large);
  flex-grow: 1;
  margin: 0;
  font-weight: normal;
}

.results-header .search-tools {
  display: none;
}

@media screen and (min-width: 970px) {
  .results-header {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 5px;
    align-items: normal;
  }
  .results-header .search-tools {
    display: block;
  }
}

.results-header .results-container {
  display: grid;
  grid-template-columns: 1fr 100px;
  width: 100%;
}


.results-header .filter-select {
  border: 1px solid #e0e0e0;
  height: 22px;
  font-size: var(--wl-font-size-x-small);
  line-height: var(--wl-line-height-xxx-large);
  font-family: var(--wl-font-family-default);
  border-radius: 16px;
  color: var(--wl-text-bdp-color-link);
  text-align: center;
}



