
.oem-technical-specifications {
  margin-top: 20px;
  margin-bottom: 20px;
}

.oem-technical-specifications .detail-data-table {
  display: flex;
  flex-wrap: wrap;
}

.oem-technical-specifications .detail-data-table .datatable-category{
  width: 50%;
  min-width: 320px;
}

.oem-technical-specifications .detail-data-table .datatable-category .datatable-value{
  min-width: 72px;
}

.oem-technical-specifications .detail-data-table .datatable-item{
  max-width: 100%;
}

.oem-technical-specifications .datatable-section{
  width: 100%;
}