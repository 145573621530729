.filter-tags {
  padding: 15px 11px 15px 11px;
  display: block;
  background-color: var(--wl-background-color-default);
  overflow-x: auto;
  white-space: nowrap;
}

.filter-tags .no-facets {
  font-size: var(--wl-font-size-xx-small);
  line-height: var(--wl-line-height-xx-small);
}

.filter-tags > .filter-tag-item {
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 7px 10px 7px 32px;
  color: var(--wl-text-color-five);
  font-size: var(--wl-font-size-xx-small);
  background: url(../../../../icons/Close.svg) no-repeat
    var(--wl-content-background-color-default);
  background-size: 12px;
  background-position: 10px;
  text-transform: capitalize;
}

.filter-tags > .filter-tag-item.make {
  text-transform: none;
}

.filter-tags > .filter-tag-item + .filter-tag-item {
  margin-left: 10px;
}

.filter-tags > .filter-tag-item:hover {
  cursor: pointer;
}

.search-filter {
  position: relative;
  padding-bottom: 6px;
}

.search-filter .slider-container {
  margin-bottom: 15px;
}
.search-filter .slider-container .rc-slider {
  position: relative;
  display: block;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: var(--wl-text-color-one);
}
.search-filter .slider-container .rc-slider .rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: var(--wl-background-color-default);
  height: 4px;
  border-radius: 6px;
}
.search-filter .slider-container .rc-slider .rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: var(--wl-background-color-three);
}
.search-filter .slider-container .rc-slider .rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.search-filter .slider-container .rc-slider .rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -4px;
  width: 9px;
  height: 9px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px var(--wl-border-color-three);
  background-color: var(--wl-background-color-three);
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.search-filter .search-filter-group {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.filter-v2 .search-filter .search-filter-group {
  gap: 8px;
}

.search-filter .search-filter-group > span {
  margin: 0px 16px;
  font-size: var(--wl-font-size-xxx-small);
  color: var(--wl-text-color-five);
}

.search-filter.length .search-filter-group > span {
  margin-right: 12px;
  margin-left: 12px;
}

.filter-v2 .search-filter.length .search-filter-group > span {
  margin-right: 4px;
  margin-left: 4px;
}

.search-filter .search-filter-group > span.current-location {
  display: block;
  text-align: center;
  font-size: var(--wl-font-size-xxx-small);
}

.search-filter .search-filter-group > .center {
  font-size: var(--wl-font-size-xx-small);
  display: block;
  text-align: center;
}

.search-filter .search-filter-group > .filter-select {
  width: 100px;
  flex-grow: 1;
  display: inline-block;
  font-size: var(--wl-font-size-xxx-small);
  padding: 8px;
  background-color: var(--wl-background-color-three);
  height: 32px;
  z-index: 200;
  color: var(--wl-text-color-five);
  border-radius: 5px;
}

.search-filter.location .search-filter-group {
  padding-top: 19px;
  padding-bottom: 10px;
}

.search-filter input[type="text"],
.search-filter input[type="number"] {
  width: 114px;
  flex-grow: 1;
  border: 1px solid var(--wl-text-color-five);
  padding: 8px 8px 9px 8px;
  border-radius: 5px;
  background-color: var(--wl-content-background-color-default);
  color: var(--wl-text-color-five);
  box-sizing: border-box;
  font-size: var(--wl-font-size-x-small);
}

.small input[type="number"],
.small input.price-input {
  width: 100%;
  height: 38px;
}

.small input.price-input::placeholder {
  color: var(--wl-text-color-default);
  font-size: 16px;
}

.search-filter .tab-content-item input[type="text"],
.search-filter .tab-content-item input[type="number"] {
  width: 100px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search-filter input.small,
.search-filter.price div.small {
  flex-grow: 0;
  width: 80px;
}

.search-filter.length input.small,
.search-filter.price div.small {
  flex-grow: 1;
}

.search-filter .filled-block {
  font-size: var(--wl-font-size-x-small);
  color: var(--wl-text-color-four);
  background: var(--wl-background-color-three);
  border: 1px solid var(--wl-text-color-five);
  border-radius: 5px;
  padding: 6px 18px;
  margin-left: 20px;
}

.search-filter.length .filled-block {
  min-width: 30px;
  max-width: 40px;
  margin-left: 12px;
  border-radius: 5px 0 0 5px;
  border-right-width: 0;
  padding-left: 0;
  padding-right: 0;
  flex-grow: 1;
  text-align: center;
  cursor: default;
  user-select: none;
}
.search-filter.length .uom-wrapper {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.filter-v2 .search-filter.length .uom-wrapper {
  background: var(--wl-background-color-default);
  border-radius: 100px;
  padding: 3px 4px;
  box-sizing: border-box;
  margin-left: 0px;
  gap: 4px;
}

.search-filter.length .uom-wrapper .filled-block {
  margin-left: 0px;
}

.search-filter.length .filled-block:last-of-type {
  border-radius: 5px;
  border-right-width: 1px;
}
.search-filter.length .filled-block + .filled-block {
  border-radius: 0 5px 5px 0;
  margin-left: 0;
}
.search-filter.length .filled-block:not(.active) {
  background: var(--wl-text-color-four);
  color: var(--wl-background-color-three);
  cursor: pointer;
}

.filter-v2 .search-filter.length .uom-wrapper .filled-block{
  border: unset;
  width: 34px;
  height: 32px;
  padding: 0px;
  margin: 0px;
  background: var(--wl-background-color-default);
  border-radius: 100px;
  font-family: 'Roboto', 'Lato', serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  color: var(--tpp-srp-filters-color);
}

.filter-v2 .search-filter.length .uom-wrapper .filled-block.active {
  background: var(--wl-content-background-color-default);
  box-shadow: 3px 1px 4px 0px var(--wl-box-shadow-eight);
}

.search-filter .collapse-content,
.filter-v2 .search-filter .collapse-content {
  border: 0 none;
}

.search-filter .collapse-content .collapsible {
  padding-right: 0;
}

.search-filter .collapse-content .header {
  padding: 10px 0px 10px 20px;
}

.search-filter .collapse-content input {
  margin-right: 10px;
}

.search-filter .collapse-content .header span a,
.search-filter .collapse-content .header h2 a {
  font-size: var(--wl-font-size-small);
  color: var(--wl-text-color-five);
}

.filter-v2 .collapse-content .header span a {
  color: var(--tpp-srp-filters-color);
}

.search-filter .collapse-content .header span a:hover,
.search-filter .collapse-content .header h2 a:hover {
  cursor: pointer;
}

.search-filter .collapse-content .header span a .icon,
.search-filter .collapse-content .header h2 a .icon {
  left: -20px;
}

.collapse-content .search-filter ul.opts li {
  color: var(--wl-text-color-five);
  padding: 6px 0;
  border-top: 0;
  display: flex;
  align-items: center;
}

.search-filter .collapse-content ul.opts li.filtered {
  display: none;
}

.collapse-content .search-filter ul.opts li:hover {
  background: 0;
}

.collapse-content .search-filter ul.opts li .custom-option {
  flex-grow: 1;
  padding: 0;
}

.collapse-content .search-filter ul.opts li a {
  font-size: var(--wl-font-size-small);
  color: var(--wl-text-color-five);
}

.filter-v2 .collapse-content .search-filter ul.opts li a {
  font-family: 'Roboto', 'Lato', serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--tpp-srp-filters-color);
}

.engine-types ul.opts li a {
  margin-left: 8px;
}

.search-filter:after {
  content: "";
  display: block;
  opacity: 0;
  clip: rect(0px, 0px, 0px, 0px);
  transition: opacity 300ms, clip 0ms linear 300ms;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--wl-preload-background-image);
}

.search-filter.loading:after {
  clip: rect(0px, 5000px, 5000px, 0px);
  opacity: 1;
  transition: opacity 300ms, clip 0ms linear 0ms;
}

.search-filter .show-more.show {
  border-top: 1px solid var(--wl-border-color-default);
}

.search-filter .filter-more a {
  color: var(--wl-text-color-link);
  font-weight: bold;
  font-size: var(--wl-font-size-xx-small);
  padding: 7px 0;
  display: block;
}

.search-filter .filter-more a:hover {
  text-decoration: underline;
}

.dollar {
  position: relative;
  font-size: var(--wl-font-size-xxx-small);
  color: var(--wl-text-color-five);
}
.dollar:before {
  position: absolute;
  top: 6px;
  content: "$";
  left: 5px;
}

.price-min,
.price-max {
  padding-left: 12px !important;
}

span.engine-details {
  color: var(--wl-text-color-twenty-two);
  font-size: var(--wl-font-size-default);
  font-weight: 500;
}

.search-filter.engine-number,
.search-filter.engine-type {
  margin-top: 8px;
}

.search-filter.engine-number {
  margin-bottom: 8px;
}


.search-filter.price input:disabled, .search-filter.length input:disabled, .search-filter.year input:disabled {
  background-color: #fafafa;
  color: #888;
  border: 1px solid #ccc;
  cursor: not-allowed;
}

.search-filter.length.length-form {
  width: 70px;
  height: auto;
  margin-bottom: 14px;
}
