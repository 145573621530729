.save-search-confirmation-modal {
  --search-alerts-confirmation-modal-window-bg: var(
    --wl-content-background-color-default
  );
  --search-alerts-confirmation-modal-check-bg: var(
    --wl-background-color-fifteen
  );
}

.save-search-confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
}

.save-search-confirmation-modal {
  width: 325px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translate(-50%, -50%);
  background-color: var(--search-alerts-confirmation-modal-window-bg);
  box-shadow: 0px 2px 10px 1px rgba(99, 99, 99, 0.66);
}

.save-search-confirmation-modal:focus {
  outline: none;
}

.save-search-confirmation-modal .check {
  background-color: var(--search-alerts-confirmation-modal-check-bg);
  width: 60px;
  height: 100%;
  float: left;
}

.save-search-confirmation-modal .check svg {
  position: relative;
  top: 35%;
  left: 35%;
}

.save-search-confirmation-modal .container {
  height: 100%;
  display: flex;
  padding: 0px 16px;
  align-items: center;
}

.save-search-confirmation-modal .container .message {
  font-size: var(--wl-font-size-medium);
  flex-grow: 1;
}

.save-search-confirmation-modal .container .closeButton {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}
