.alert {
  border: 1px solid var(--wl-border-color-default);
  border-radius: 4px;
  line-height: 1.5em;
  display: flex;
  background: var(--wl-content-background-color-default);
  margin: 0px 10px 10px 15px;
  padding: 4px;
  -webkit-text-size-adjust: 100%;
}

.alert .icon {
  background-image: url(../../icons/alert2.svg);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50%;
  float: left;
  width: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50px;
}

.alert .text {
  padding: 4px;
  float: left;
}

.alert .bold {
  font-weight: bold;
}
