.mobile-refine-search-height{
  display: none;
}
.content-head {
  margin-left: auto;
  margin-right: auto;
  max-width: 1133px;
}

.content-head h1:not(h1.branded-srp-header__title) {
  margin: 0;
  padding: 15px 15px 15px;
  color: var(--wl-text-color-default);
  font-size: var(--wl-font-size-xxx-large);
  line-height: var(--wl-line-height-x-large);
}

.content-head-v2 h1:not(h1.branded-srp-header__title) {
  margin: 0;
  padding: 15px 15px 15px;
  color: var(--wl-text-color-default);
  font-size: var(--wl-font-size-xxx-large);
  line-height: var(--wl-line-height-x-large);
}

.content-head-v2 .breadcrumb {
  margin-left: auto;
  margin-right: auto;
  max-width: 1133px;
}

.main-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1133px;
  background: var(--wl-content-background-color-default);
  padding: 0 9px;
}

.main-content:after {
  display: table;
  content: '';
  clear: both;
}

.right-rail-ad.ad-300 {
  min-height: 250px;
}

.sticky {
  position: fixed;
  top: 40px;
  margin-left: auto;
  z-index: 100;
  height: auto;
  background: var(--wl-content-background-color-default);
}

.sticky-ads {
  position: -webkit-sticky;
  position: sticky;
  top : 41px;
  z-index: 100;
  height: auto;
  background: var(--wl-content-background-color-default);
}

.price-disclaimer {
  margin: 20px 0;
  font-size: 14px;
  color: var(--wl-text-color-five);
}

@media screen and (min-width: 768px) {
  .main-content {
    padding: 0 20px;
  }
}

@media screen and (min-width: 970px) {
  .main-content {
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }

  .main-content #right-content {
    flex-grow: 1;
    padding-right: 15px;
  }

  .main-content #left-content {
    flex-shrink: 0;
    width: 300px;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
}

@media screen and (max-width: 969px) {
  #div-gpt-ad-mobile-leaderboard-custom {
    min-height: 50px;
  }
  .sticky-mobile-add {
    position: sticky;
    top: 0;
    z-index: 11;
    width: 100%;
    background-color: #FFFFFF;
  }
}

.ws-narrative-wrapper {
  display: block;
  max-width: 1133px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem 2rem;
  box-sizing: border-box;
  background-color: white;
  padding-top: 2rem;
}

.ws-narrative-wrapper h3 {
  color: #0f4d77;
  margin-top: 0;
}

.ws-narrative-wrapper p {
  margin-top: 0;
  margin-bottom: 30px;
}

.information-wrapper {
  display: block;
  max-width: 1133px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem 2rem;
  box-sizing: border-box;
}

.information-wrapper-position-bottom {
  background-color: #f7f7f7;
  padding-top: 2rem;
}

.interesting-information-image {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}

.interesting-information-title {
  color: #2c3d4d;
  margin-top: 0;
}

.interesting-information-link, .interesting-information-description a {
  cursor: pointer;
  color: var(--wl-text-color-seven);
}

.interesting-information-description p {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .information-container {
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
  }
  .right-rail-ad.ad-300 {
    display: none;
  }
}

@media screen and (max-width: 969px) {
  .mobile-refine-search-height {
    display: grid;
    min-height: 46px;
    position: sticky;
    top: 0;
    z-index: 100;
  }
}

.information-wrapper-position-top {
  display: none;
}
@media screen and (min-width: 768px) {
  .information-wrapper-position-top {
    display: block;
  }
}
