.address-modal-container {
  display: block;
}
.address-modal-container.modal {
  align-items: center;
  background-color: var(--wl-box-shadow-two);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.address-modal {
  background-color: var(--wl-content-background-color-default);
  box-shadow: 0 5px 15px var(--wl-box-shadow-two);
  display: grid;
  flex-direction: column;
  gap: 16px;
  grid-template-rows: auto;
  padding: 16px;
  position: absolute;
  width: 85%;
}
.address-modal-title {
  font-size: var(--wl-font-size-large);
  font-weight: bold;
  line-height: var(--wl-line-height-default);
}
.address-modal-direction {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  margin: 0px;
  white-space: break-spaces;
}

.address-modal button.address-close {
  background: url('../../icons/Close.svg') no-repeat center center transparent;
  border-width: 0;
  display: block;
  height: 14px;
  margin: 21px;
  right: 0;
  top: 0;
  position: absolute;
  width: 14px;
}

.location-map-wrapper {
  height: 222px;
}
@media screen and (min-width: 769px) {
  .address-modal {
    grid-template-rows: auto auto 35px auto;
    min-width: 474px;
    max-width: max-content;
  }
  .address-modal .form-button-array {
    justify-content: space-between;
  }
}
