.search-tools.sort {
  width: auto;
  height: auto;
  position: static;
  top: 0;
  right: 0;
  background: transparent;
  text-align: right;
  padding: 0;
  font-size: var(--wl-font-size-x-small);
  overflow: visible;
}

.search-tools.sort .collapse-content {
  border: 0;
}

.search-tools.sort .collapsible {
  position: relative;
  overflow: visible;
}

.search-tools.sort .tool-set {
  transform: none;
  width: auto;
  left: 0;
  position: static;
  display: block;
  overflow-y: visible;
  padding: 0;
  background: 0;
}

.search-tools.sort .tool-set .inner {
  float: right;
  padding-bottom: 0;
  outline: none;
  overflow: visible;
}

.search-tools.sort .tool-set .sort-title {
  background: 0;
  padding: 0 10px;
  border: 0 none;
}

.search-tools.sort .tool-set .header span.sort-title-header {
  box-sizing: unset;
  font-family: inherit;
  display: inline-block;
  border-radius: 4px;
  width: auto;
  font-size: var(--wl-font-size-xxx-small);
  font-weight: normal;
  line-height: 0;
  margin: 0;
  position: relative;
  color: var(--wl-text-color-five);
}

.search-tools.sort .tool-set h2:before {
  color: var(--wl-text-color-seven);
  font-size: var(--wl-font-size-xxx-small);
  font-weight: bold;
  margin-right: 5px;
}

.search-tools.sort .tool-set h2:hover {
  cursor: pointer;
}

.search-tools.sort .tool-set .header .criteria {
  display: inline;
  font-size: var(--wl-font-size-xxx-small);
}

.search-tools.sort .tool-set .header span.sort-title-header a {
  font-weight: normal;
  display: inline;
  font-size: var(--wl-font-size-medium);
  position: static;
}

.search-tools.sort .tool-set .header span.sort-title-header > a {
  align-items: center;
  color: var(--wl-text-color-default);
  font-size: var(--wl-font-size-small);
  font-weight: bold;
  display: flex;
  line-height: var(--wl-line-height-small);
}

.search-tools.sort .tool-set .header span.sort-title-header span {
  color: var(--wl-text-color-five);
  font-weight: normal;
}

.search-tools.sort .tool-set ul.opts {
  transition: max-height .2s ease-in-out;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  margin: 4px 0;
  background-color: var(--wl-content-background-color-default);
  border: 1px solid var(--wl-box-shadow-three);
  border-radius: 4px;
  box-shadow: 0 6px 12px var(--wl-box-shadow-three);
  text-align: left;
  padding: 5px 0;
  height: auto;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
}

.search-tools.sort .tool-set .open > .collapsible > ul.opts {
  visibility: visible;
  max-height: none;
  overflow-y: auto;
}

.search-tools.sort .tool-set ul.opts li {
  border: none;
  white-space: nowrap;
  padding: 0;
}

.search-tools.sort .tool-set ul.opts li .custom-option,
.search-tools.sort .tool-set ul.opts li label {
  border-width: 0;
  padding: 8px 12px;
  font-size: var(--wl-font-size-medium);
  color: var(--wl-text-color-default);
  cursor: pointer;
}

.search-tools.sort .tool-set ul.opts li .custom-option.selected,
.search-tools.sort .tool-set ul.opts li label.selected {
  color: var(--wl-brand-color-three);
  font-weight: normal;
  background: var(--wl-brand-color-one);
}

.search-tools.sort .tool-set li .custom-option:after {
  display: none;
}

.search-tools.sort .tool-set li input {
  position: absolute !important;
  height: 1px!important;
  width: 1px!important;
  overflow: hidden!important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

@media screen and (min-width: 768px) {
  .search-tools.sort .tool-set .header span.sort-title-header {
    font-size: var(--wl-font-size-x-small);
  }

}

@media screen and (min-width: 970px) {
  .search-tools.sort .tool-set .header span.sort-title-header span {
    margin-left: 4px;
  }

  .search-tools.sort .collapse-content .header a .icon {
    position: static;
  }

  .search-tools.sort .tool-set .header .criteria {
    font-size: var(--wl-font-size-medium);
  }

  .search-tools.sort .tool-set .header span.sort-title-header > a {
    font-size: var(--wl-font-size-medium);
  }

}