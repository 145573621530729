.search-filter.price .symbol {
  top: 7px;
  position: absolute;
}

.currency-symbol-suffix,
.currency-symbol-prefix {
  position: relative;
  font-size: var(--wl-font-size-xxx-small);
  color: var(--wl-text-color-five);
}

.currency-symbol-suffix .symbol {
  right: 10px;
}

.currency-symbol-prefix .symbol {
  left: 5px;
}
