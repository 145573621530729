.tool-set {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--wl-content-background-color-default);
  z-index: 1000;
  transition: transform 300ms ease;
  padding: 15px 15px 60px;
  box-sizing: border-box;
}

.tool-set.open {
  transform: translateX(100%);
}

.tool-set .inner {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 60px;
}

.filter-v2.mobile-search-filters .tool-set .inner {
  scrollbar-color: var(--wl-background-color-nine-double) var(--wl-background-color-default);
  scrollbar-width: thin;
}

.filter-v2.mobile-search-filters .tool-set .inner::-webkit-scrollbar-thumb {
  background-clip: border-box;
}

.tool-set ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tool-set .opts {
  max-height: 350px;
  overflow-y: auto;
}

.filter-v2 .tool-set .opts {
  max-height: 450px;
}

.filter-v2 .search-filter.fuel-type,
.filter-v2 .search-filter.hull-material,
.filter-v2 .collapsible-internal-content,
.filter-v2 .filter-typeahead-part {
  background: var(--tpp-srp-filters-background-color);
  border: 1px solid var(--tpp-srp-filters-border-color);
  border-radius: 8px;
  padding: 8px 4px 0px 8px;
  margin-bottom: 8px;
}

.filter-v2 .search-filter.fuel-type:not(:has(:first-child)),
.filter-v2 .search-filter.hull-material:not(:has(:first-child)),
.filter-v2 .collapsible-internal-content:not(:has(:first-child)),
.filter-v2 .filter-typeahead-part:not(:has(:first-child)) {
  display: none;
}

.filter-v2 .tool-set ul.opts {
  margin-bottom: 8px;
}

.filter-v2 .collapsible.internal {
  padding-right: 8px;
}

.filter-v2.three-column-listing .tool-set .collapsible.internal {
  border: 0px;
  border-radius: unset;
}

.filter-v2 .tool-set .filter-typeahead-part ul.opts {
  background: unset;
  border: 0px;
  border-radius: unset;
  padding: unset;
}
 .filter-v2 ul.opts {
  scrollbar-color: var(--wl-background-color-nine-double) transparent;
  scrollbar-width: thin;
}
.filter-v2 ul.opts::-webkit-scrollbar {
  width: 12px;
  scrollbar-color: var(--wl-background-color-nine-double) transparent;
}

.filter-v2 ul.opts::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: var(--wl-background-color-nine-double); 
}

.filter-v2 .show-more ul.opts {
  overflow-y: scroll;
  padding-right: 0px;
}

.filter-v2 .collapse-content .collapsible .dropdown-input-container {
  background-color: var(--tpp-srp-filters-dropdown-background-color);
}


.filter-v2 .collapse-content .search-filter ul.opts li {
  border-radius: 6px;
  padding: 7px 8px 7px 8px;
  background: var(--wl-content-background-color-default);
  margin-bottom: 8px;
  margin-right: 4px;
  border: 0.5px solid var(--tpp-srp-filters-border-color);
  font-family: 'Roboto', 'Lato', serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--tpp-srp-filters-color);
  gap: 8px;
}
.filter-v2 .search-filter .collapse-content .header {
  padding-left: 24px;
}


.filter-v2 .search-filter .collapse-content .header span a .icon,
.filter-v2 .search-filter .collapse-content .header h2 a .icon {
  left: -28px;
}

.filter-v2 .collapse-content .search-filter ul.opts li:last-child {
  margin-bottom: 0;
}

.filter-v2 .collapse-content .search-filter ul.opts li:hover {
  background-color: var(--wl-content-background-color-default);
}

.filter-v2 .search-filter .collapse-content input {
  margin: 3px 3px 3px 4px;
}

.filter-v2.three-column-listing .tool-set .collapse-content .search-filter ul.opts li input[type="checkbox"],
.filter-v2.three-column-listing .tool-set .collapse-content .search-filter ul.opts li input[type="radio"] {
  margin: 0px;
  margin-right: 2px;
  transform: scale(1.1);
  cursor: pointer;
}

.filter-v2.three-column-listing .search-tools.refine-search > .tool-set,
.filter-v2.three-column-listing .search-side-bar > .tool-set {
  padding: 18px 8px 0px;
  border: 1px solid var(--wl-border-color-default);
  border-radius: 5px;
}

.tool-set li {
  color: var(--wl-text-color-six);
  padding: 0 20px;
  border-top: 1px solid var(--wl-input-box-border-color);
  max-height: 1000000px;
}

.tool-set li:hover {
  background-color: var(--wl-background-color-default);
}

.price-dropdown {
  min-width: 70px;
  margin-left: 10px;
  margin-right: -7px;
}

.filter-v2 .price-dropdown {
  margin-left: 0px;
  margin-right: 0px;
}
.price-dropdown li {
  border: unset;
}

.price-dropdown li:hover {
  background-color: #f5f5f5
}

.tool-set li.filtered {
  display: none;
}

.tool-set li .custom-option {
  cursor: pointer;
  padding: 12px 0;
  outline: none;
  overflow: hidden;
}

.tool-set li .custom-option.selected {
  color: var(--wl-text-color-one);
  font-weight: bold;
  font-size: var(--wl-font-size-medium);
}

.tool-set li .custom-option:after {
  opacity: 0;
  transition: all 200ms ease-in-out;
  width: 16px;
  margin-right: 0;
  height: 12px;
  float: right;
  transform: translateX(35px);
}

.tool-set li .custom-option.selected:after {
  opacity: 1;
  transform: translateX(0);
}

.tool-set .buttons {
  box-sizing: border-box;
  display: block;
  height: 54px;
  z-index: 1100;
  transition: transform 300ms ease, opacity 300ms ease;
  opacity: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 12px;
  background: var(--wl-content-background-color-default);
  transform: translateY(100%);
}

.tool-set.open .buttons {
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms ease 500ms, opacity 300ms ease;
}

.tool-set .btn-container {
  height: 30px;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.tool-set .btn-action {
  box-shadow: none;
  border-radius: 5px;
  font-size: var(--wl-font-size-xx-small);
  display: block;
  cursor: pointer;
  border: 1px solid var(--wl-border-color-one);
  padding: 8px 0;
  background-color: var(--wl-background-color-one);
  color: var(--wl-text-color-four);
  width: 100%;
  height: 30px;
  line-height: 1.25;
  text-align: center;
  transition: transform 300ms ease;
}

.tool-set .btn-action + .btn-action {
  position: absolute;
  left: 0;
  top: 0;
}

.tool-set .btn-action.hidden {
  transform: translateX(100%);
}

.tool-set .btn-action.cancel {
  color: var(--wl-text-color-sixteen);
  background: var(--wl-background-color-two);
  border: var(--wl-border-color-two);
}

.tool-set .filter-txt label {
  display: block;
  font-weight: 700;
  font-size: 0.875em;
  margin-bottom: 5px;
}

.tool-set .filter-txt input[type="text"] {
  width: 100%;
  border: 1px solid var(--wl-text-color-five);
  padding: 8px 8px 9px 8px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--wl-text-color-five);
  box-sizing: border-box;
  font-size: var(--wl-font-size-xxx-small);
}

.tool-set .filter-txt.filter-txt-icon input[type="text"] {
  padding-left: 30px;
  position: relative;
}

.tool-set .filter-txt.filter-txt-icon:before {
  content: '';
  background: url(../../icons/Search.svg) no-repeat;
  position: absolute;
  height: 20px;
  width: 20px;
  display: block;
  background-size: contain;
  top: 4px;
  left: 5px;
}

.tool-set-header {
  background: white;
  border-bottom: 1px solid #F5F5F5;
  display: flex;
  height: 55px;
  justify-content: space-between;
}

.tool-set-header-title {
  margin: 0;
  padding: 19px 16px;
  font-size: 16px;
  color: #343434;
}

.tool-set-btn-close {
  background: #fff;
  background-image: url(../../icons/CloseFilters.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 14%;
  cursor: pointer;
}

@media screen and (min-width: 515px) {
  .tool-set-btn-close {
    width: 8%;
  }

  .tool-set-header-title {
    width: 90%;
  }
}

.filter-v2.three-column-listing .tool-set .filter-txt input[type=text],
.filter-v2 .tool-set .filter-txt input[type=text] {
  box-shadow: 0px 2px 3px 0px var(--wl-box-shadow-eight) inset;
  border-radius: 4px;
  padding: 8px 15px;
  padding-left: 42px;

  border: solid 1px var(--wl-border-color-fourteen);
  height: 40px;

  font-family: 'Roboto', 'Lato', serif;
  color: var(--tpp-srp-filters-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 16px;
}

.filter-v2.three-column-listing .tool-set .filter-txt input[type=text]::placeholder, 
.filter-v2 .tool-set .filter-txt input[type=text]::placeholder {
  color: var(--tpp-srp-filters-color);
}

.filter-v2.three-column-listing .tool-set .filter-txt.filter-txt-icon:before,
.filter-v2 .tool-set .filter-txt.filter-txt-icon:before {
  top: 9px;
  left: 16px
}

.filter-v2.three-column-listing .search-filter .search-filter-group input[type=number],
.filter-v2 .search-filter .search-filter-group input.price-input,
.filter-v2 .search-filter .search-filter-group input[type=number]{
  border: 1px solid var(--tpp-srp-filters-border-color);
  font-family: 'Roboto', 'Lato', serif;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px var(--wl-box-shadow-eight) inset;
}

.filter-v2 .search-filter .search-filter-group input.invalid-input {
  border: 1px solid var(--wl-border-color-invalid);
  outline: 2px solid var(--wl-border-color-invalid);
  outline-offset: -2px;
}

.filter-v2 .search-filter .number-range-error-messages {
  color: var(--wl-text-color-invalid);
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.filter-v2.three-column-listing .search-filter .search-filter-group input[type=number]::placeholder,
.filter-v2 .search-filter .search-filter-group input[type=number]::placeholder {
  color: var(--tpp-srp-filters-color);
}

.filter-v2 .search-filter .search-filter-group > span {
  font-family: 'Roboto', 'Lato', serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--tpp-srp-filters-color);
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 430px) {
  .filter-v2 .search-filter .search-filter-group input[type=number]{
    font-size: 16px;
  }
}
