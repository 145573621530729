.dynamic__content {
  margin-bottom: 40px;
}

.dynamic__content h2 {
  flex: 0 0 auto;
  color: var(--wl-text-color-default);
  margin: 0 auto;
  font-size: var(--wl-font-size-large);
  flex-basis: 100%;
  line-height: var(--wl-line-height-large);
}

.dynamic__content p {
  margin-top: 5px;
}
