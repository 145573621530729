.form-button-array {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  list-style: none;
  margin: -4px;
  padding: 0px;
}
.form-button {
  align-items: center;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
  color: var(--wl-text-color-four);
  display: flex;
  font-size: var(--wl-font-size-medium);
  flex-grow: 1;
  line-height: var(--wl-line-height-default);
  padding: 9px 16px 7px;
  z-index: 4;
}

.form-button.primary {
  background: var(--wl-background-color-one);
}

.form-button.secondary {
  background: var(--wl-text-color-eight);
}

.form-button-element {
  display: grid;
  grid-template-columns: 16px max-content;
  grid-gap: 4px;
  justify-content: center;
}

.form-button-element:before {
  content: '';
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  height: 16px;
  width: 16px;
}

.form-button-element-place:before {
  background-image: url(../../icons/place.svg);
}

.form-button-element-email:before {
  background-image: url(../../icons/Email.svg);
  background-size: contain;
}

.form-button-element-call:before {
  background-image: url(../../icons/Call.svg);
}

.form-button-element-call-made:before {
  background-image: url(../../icons/call_made.svg);
}
.form-button-array li {
  display: flex;
  margin: 4px;
}
@media screen and (min-width: 769px) {
  .form-button-array {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}